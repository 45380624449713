.team-member-box {
    // Variables
    $team-member-box-height: 35rem;
    $team-member-box-width: 30rem; 
    
    position: relative;
    justify-self: center;
    width: 70%;
    margin-bottom: 5rem;
    height: $team-member-box-height + 12rem;
    @include breakpoint-down(xsmall){
        height: $team-member-box-height / 4 * 3 + 12rem;
        margin-bottom: 2rem;
    }
    z-index: $zi-base;
    
    &__image-content{
        height: $team-member-box-height;
        width: $team-member-box-width;
        position: relative;
        
        @include breakpoint-down(xsmall){
            height: $team-member-box-height / 4 * 3;
            width: $team-member-box-width  / 4 * 3;
        }

        img {
            // position: absolute;
            float: right;
            padding: 4rem 0 0 3rem;
            bottom: 12rem;
            height: 100%;
        }
        @include clearfix;
        &:hover :nth-child(1){
            height: 100%;
            width: 75%;
        }
    }
    
    
    &__background{
        position: absolute;
        top: 0;
        left: 0;
        width: 60%;
        height: 70%;
        z-index: $zi-behind;
        transition: height .5s, width .5s;
        &--1{
            background-color: $secondary-accent-1;
        }
        &--2{
            background-color: $primary-color;
        }
        &--3{
            background-color: $secondary-dark;
        }
        &--4{
            background-color: $secondary-accent-2;
        }
    }
    


    &:hover{
        cursor: pointer;
    }

    &__description {
        h2 {
            font-size: $headline-small-ft;
        }
        h3 {
            font-size: $body-ft;
        }
    }

}