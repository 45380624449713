.contact-section{

    &__background {
        background: linear-gradient(to bottom right, #fff 0%, #fff 50%, $secondary-accent-2 50%, $secondary-accent-2 100%);
        margin-top: 4rem;
        padding-bottom: 3rem;       
    }
    .highlight-headline-box{
        margin: 3rem 5rem;
        @include breakpoint-down(xsmall){
            margin: 3rem 2rem;
        }
    }
    
    .form-container {
        width: 60%;
        margin:auto;
        padding: 1.5rem 3rem;
        @include breakpoint-down(medium){
            width: 80%;
        }
        @include breakpoint-down(xsmall){
            width: 90%;
            padding: 1.5rem 1rem;
        }
        background-color: $secondary-bright-2;
        border: 1px solid $contrast-dark;
        border-radius: 3px;
        font-size: $body-ft;
    }
}
