.nav {
    $nav-padding: 3rem 4rem;
    $nav-margin: 0 0rem;
    position: relative;
    z-index: $zi-top-ui;
    &__content, &__content--footer {
        float: right;
        color: $contrast-dark;
        list-style-type: none;
        border-radius: 3px;
        font-size: $small-ft;
        
        &:last-child {
            margin-right: 2rem;
        }
        @include clearfix;
    }

    &.hide-on-medium-down {
        $top-bottom-border: 3px solid $primary-color;
        background-color: $secondary-bright;
        border-top: $top-bottom-border;
        border-bottom: $top-bottom-border;        
    }
    
    &__link {
        cursor: pointer;
        margin: $nav-margin;
        padding: $nav-padding;
        display: inline-block;
        text-decoration: none;
        &:link,
        &:visited {
            color: $contrast-dark;
        }
    }
    

    &__second-lev-ul, &__second-lev-ul--footer {
        display: none;
        &:last-child{
            border-radius: 0 0 8px 8px;    
        }
    }

    &__dropdown::after{
        content: "\232A";
        display: inline-block;
        transform: translateX(-3.5rem) rotate(90deg) translateX(.5rem);
        transition: transform 300ms ease-in-out;
    }

    &__first-lev, &__first-lev--footer {
        display: inline-block;
        position: relative;
        &:hover, &:active, &:focus {
            &::after{
                transform: translateX(-3.5rem) rotate(-90deg) translateX(.5rem);
            }

            // cursor: pointer;
            @include navunderline;
            .nav__second-lev-ul {
                position: absolute;
                display: block;
                width: 170%;
                left: 0;
                background-color: rgba($secondary-bright, .8);
            }
        }
    }

    &__second-lev {
        .nav__link{
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
        display: block;  
        :hover {
            color: $secondary-accent-1;
        }                  
    }

    $mobile-header-bkg-height: 8rem;
    &__logo {
        display: inline-block;
        svg{
            height: 8rem;
            .cls-1{fill:#fff;}
            .cls-2{fill:#323e48;}
            .cls-3{fill:#a2d45e;}
        }

        .hide-on-large-up & {
            $top-bottom-border: 2px solid $primary-color;
            width: 100vw;
            border-bottom: $top-bottom-border;        
            height: $mobile-header-bkg-height;
            background-color: $secondary-bright;
        }
    }


    &--hamburger{
        z-index: $zi-absolute-top;
        position: fixed;
        top: 0;
        right: 0;
        display: inline-block;
    }
    
    &--hamburger__hamburger-icon {
        $hamburger-background-dim: $mobile-header-bkg-height/3*2;
        position: relative;
        z-index: $zi-absolute-top;
        top: ($mobile-header-bkg-height - $hamburger-background-dim)/2;
        right: 1rem;
        width: $hamburger-background-dim;
        height: $hamburger-background-dim;
        background-color: $secondary-bright-2;
        border: 2px solid $secondary-accent-1;
        border-radius: 50%;
        fill: $secondary-accent-1;
        :hover{
            cursor: pointer;
        }
        
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -38%);
            width: 3rem;
            height: 3rem;
            rect{
                transition: transform 300ms ease-in-out, opacity 200ms ease-in-out;
            }
            > rect:last-child {
                transform-origin: 10% 72%;
            }
        }
        &.open {
            svg{
                > rect:first-child{
                    transform: translateX(2rem) rotate(45deg);
                }
                > rect:nth-child(2){
                    opacity: 0;
                }
                > rect:last-child {
                    transform: translateX(2rem) rotate(-45deg);
                }
            }
        
        }
    }
    
    &--hamburger--center-container{
        position: relative;
    }

    &--hamburger--center{
        position: fixed;
        height: 100%;
        min-width: 25rem;
        right: -25rem;
        z-index: $zi-absolute-top-bg;
        background-color: $secondary-accent-1;
        border-left: 1px solid $secondary-bright-2;
        transition: transform 500ms ease-in-out;
        &.open {
            transform: translate(-25rem, 0);
        }
    }
    
    &__content--hamburger{
        margin: auto;
        
        .nav__link:link, .nav__link:visited {
            color: $secondary-bright;
            font-size: $very-small-ft;
        }


        ul {
            list-style-type: none;
        }
        .nav__second-lev--hamburger {
            padding-left: 1rem;
        }
    }


    
}

// Appear-on-scroll header
.nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    &.hide-on-medium-down {
        transition: transform 600ms;
    }
}

.nav.scroll-down {
    transform: translate3d(0, -100%, 0);
}
.nav.scroll-up {
    transform: none;
}
