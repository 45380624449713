@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes animate-aim {
    from {
        transform: rotate(0deg);
    }
    // 50% {
    //     transform: rotate(0deg);
    // }
    to {
        transform: rotate(90deg);
    }
}

// Ruler
@keyframes animate-ruler-tick-1 {
    from {
        transform: translate(0%, 0%);
    }
    50% {
        transform: translate(43%, 43%);
    }
    to {
        transform: translate(0%, 0%);
    }
}

@keyframes animate-ruler-tick-2 {
    from {
        transform: translate(0%, 0%);
    }
    50% {
        transform: translate(29%, 29%);
    }
    to {
        transform: translate(0%, 0%);
    }
}

@keyframes animate-ruler-tick-3 {
    from {
        transform: translate(0%, 0%);
    }
    50% {
        transform: translate(15%, 15%);
    }
    to {
        transform: translate(0%, 0%);
    }
}
// Ruler end
@keyframes animate-analytics {
    from {
        // transform: rotate(0deg);
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes animate-rocket {
    from {
        transform: translate(0%, 0%);
        opacity: 1;
    }
    30% {
        transform: translate(-30%, 22%);
        opacity: 0;
    }
    80% {
        opacity: 0;
        transform: translate(0%, 0%);
    }
    to {
        opacity: 1;
    }
}

@keyframes animate-controller {
    from {
        transform: translate(0%, 0%);
    }
    50% {
        transform: translate(0%, 30%);
    }
    to {
        transform: translate(0%, 0%);
        
    }
}


@keyframes animate-top-leaf {
    from {
        transform: translateY(0) scale(1);
    }
    60% {
        transform: translateY(4%) scale(.7);
    }
    to {
        transform: translateY(-3%) scale(.8);      
    }
}
@keyframes animate-middle-leaf {
    from {
        transform: translateY(0) scale(1);
    }
    60% {
        transform: translateY(3%) scale(.85);
    }
    to {
        transform: translateY(-2%) scale(.9);
        
    }
}

.path-anim-slow {
    fill: none;
    animation: dash 1600ms ease-out forwards;
}

.path-anim-mid {
    fill: none;
    animation: dash 800ms ease-out forwards;
}


.leaf-anim, .path-anim-fast {
    fill: none;
    animation: dash 400ms ease-out forwards;
}