.connected-container {
    position: relative;
    margin: 2rem 12rem;
    padding: 6rem 14rem;
    
    @include breakpoint-up(xlarge){
        margin: 1.5rem 8rem;
    }
    @include breakpoint-down(medium){
        margin: 2rem 8rem;
        padding: 4rem 4rem;
    }
    @include breakpoint-down(small){
        margin: 2rem 6rem;
        // Safari 10.1+ only
        // https://www.ryadel.com/en/css3-media-query-target-only-ie-ie6-ie11-firefox-chrome-safari-edge/#Safari_71
        @media not all and (min-resolution:.001dpcm) { 
                margin: 1.8rem 6rem;
        }
    }
    @include breakpoint-down(xsmall){
        margin: 1.5rem 5rem;
        padding: 4rem 3rem;
        // Safari 10.1+ only
        // https://www.ryadel.com/en/css3-media-query-target-only-ie-ie6-ie11-firefox-chrome-safari-edge/#Safari_71
        @media not all and (min-resolution:.001dpcm) { 
            margin: 1.32rem 5rem;
        }
    }
  

    text-align: center;
    background-color: rgb(246, 246, 246);
    border-radius: 6px;
    .paragraph {
        font-family:  'Miriam Libre', sans-serif;
    }

    &::after{
        content:"";
        position: absolute;
        top: calc(-1 * var(--border-size-vision-mission));
        left: calc(-1 * var(--border-size-vision-mission));
        display: inline-block;
        width: calc(100% + 2 * var(--border-size-vision-mission));
        height: calc(100% + 2 * var(--border-size-vision-mission));
        border-radius: 6px;
        z-index: $zi-behind;
      }
}

.vision-text-highlight, .mission-text-highlight, .presence-text-highlight, .expertise-text-highlight {
    display: inline-block;
    padding: 0 .2rem;
}

.vision-text-highlight, .mission-text-highlight {
    color: $primary-color;
}

.presence-text-highlight, .expertise-text-highlight {
    background-color: $secondary-accent-1;
    color: $secondary-bright;
}

.vision-container {
    &::after{
        background:linear-gradient(to right, transparent 50%, $secondary-accent-2 50%);
      }
}

.mission-container {
    &::after{
        background:linear-gradient(to right, $secondary-accent-2 50%, transparent 50%);
      }
}

.presence-container {
    &::after{
        background:linear-gradient(to right, transparent 50%, $secondary-accent-1 50%);
    }
}

.expertise-container {
    &::after{
        background:linear-gradient(to right, $secondary-accent-1 50%, transparent 50%);
      }
}