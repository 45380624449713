.section-plant-based{
    height: 70rem;
    @include breakpoint-down(small){
        min-height: 60rem;
    }
    position: relative;
    .plant-based-content {
        position: absolute;
        top: 30%;
        left: 10%;
        
        @include breakpoint-down(small){
            width: 80vw;
        }
        @include breakpoint-down(xsmall){
            width: 90vw;
        }
        @include breakpoint-down(medium){
            left: 2%;
         }
        @include breakpoint-down(small){
            top: 5%;
            left: 50%;
            transform: translateX(-50%);
            background-color: rgba($secondary-bright, .8);
            border-radius: 3px;
            border: 1px solid $secondary-rest-1;
            padding: 1rem;
        }

        .highlight-headline-box{
            background-color: $secondary-accent-4;
            margin: 0;
            margin-bottom: 2rem;
            text-align: center;
        }
        .paragraph {
            font-size: $body-small-ft;
            width: 40rem;
            margin-left: 4rem;
            @include breakpoint-down(small){
                width: auto;
                margin-left: 0;
            }
        }

        @include breakpoint-down(small){
            &__button {
                text-align: center;
            }
        }
    }
    
    &--plant-page{
        height: 50rem;
        .plant-based-content {
            position: absolute;
            top: 10%;
            left: 10%;
        }

    }
} 


.plant-based__go-to {
    position: absolute;
    top: 85%;
    left: 65%;
}


$plant-based-bg-midpoint: 80%;
$plant-based-bg-midpoint-small-screen: 70%;
.plant-based-background {
    height: 100%;
    &--standalone{
        background: linear-gradient(to right, transparent 0%, transparent $plant-based-bg-midpoint, $secondary-bright-2 $plant-based-bg-midpoint, $secondary-bright-2 100%);

        @include breakpoint-down(small){
            background: linear-gradient(to right, transparent 0%, transparent $plant-based-bg-midpoint-small-screen, $secondary-bright-2 $plant-based-bg-midpoint-small-screen, $secondary-bright-2 100%);
        }
    }

    &--plant-page{
        .upper-left-leaf, .upper-right-leaf{
            transform-origin: 50% 78%;
            animation: animate-top-leaf 1400ms 400ms ease-in-out forwards;
        }
        .middle-left-leaf, .middle-right-leaf{
            transform-origin: 50% 98%;
            animation: animate-middle-leaf 1400ms 400ms ease-in-out forwards;
        }
    }


    .svg-background{
        display: block;
        width: 100%;
        height: 100%;
        fill: none;
        
        path, line {
            stroke-width: 5px;
            stroke: $secondary-accent-4;
            stroke-linecap: round;
            @include breakpoint-down(small){
                display: none;
            }
        }
        // Safari 10.1+ only
        // https://www.ryadel.com/en/css3-media-query-target-only-ie-ie6-ie11-firefox-chrome-safari-edge/#Safari_71
        @media not all and (min-resolution:.001dpcm) { 
            .not-on-safari{
                stroke-width: 0px;
            }
        }
    }

    .plant-svg {
        svg{
            position: absolute;
            left: $plant-based-bg-midpoint;
            top: 20%;
            width: 30rem;
            @include breakpoint-down(small){
                top: 60%;
                left: $plant-based-bg-midpoint-small-screen;
                width: 18rem;
            }
            transform: translate(-50%, 0);
            path {
                fill:none;
                stroke-width: 3px;
                stroke-linecap: round;
                stroke: $secondary-rest-1;
            }
        }
    }
    
}
