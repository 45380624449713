.page-sidebar {
    position: fixed;
    z-index: $zi-mid;
    top: 0;
    left: 0;
    background-color: $secondary-accent-2;
    width: $sidebar-gutter;
    height: 100vh;
    flex-direction: column;
    justify-content: center;

    &__content{
        color: $contrast-dark;
        text-align: center;
        white-space: nowrap;
        text-transform: uppercase;
        font-size: $small-ft;
        letter-spacing: .4rem;
        transform: rotate(90deg) translateX(-50%);
    }
}