// ems based on user settings, so usually 16px
// 427px, 640px, 1024px, 1400px, 1860px, 2300px
$breakpoints-up: ("small": "26.6875em", "medium": "40em", "large": "64em", "xlarge": "87.5em", "xxlarge": "116.25em", "xxxlarge": "143.75em");

// 426px ,639px, 1023px, 1399px
$breakpoints-down: ("xsmall": "26.526em", "small": "39.9375em", "medium": "63.9375em", "large": "87.4375em");
  
@mixin breakpoint-up($size){
    @media (min-width: map-get($breakpoints-up, $size)){
        @content;
    }
}
  
@mixin breakpoint-down($size){
    @media (max-width: map-get($breakpoints-down, $size)){
        @content;
    }
}