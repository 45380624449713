// Header section
.header__hero {
    height: 100vh;
    background-image: linear-gradient(to right bottom, rgba($secondary-dark, .8), rgba($secondary-rest-1, 0.4) 60%), url("../../assets/img/noah-buscher-small-min.jpg");
    @include breakpoint-up(medium){
        background-image: linear-gradient(to right bottom, rgba($secondary-dark, .8), rgba($secondary-rest-1, 0.4) 60%), url("../../assets/img/noah-buscher-large-min.jpg");
    }
    @include breakpoint-up(large){
        background-image: linear-gradient(to right bottom, rgba($secondary-dark, .8), rgba($secondary-rest-1, 0.4) 60%), url("../../assets/img/noah-buscher-orig-min.jpg");
    }
    background-size: cover;
    background-position: center;
    @include breakpoint-down(small){
        height: 60vh;
    } 
    position: relative;
    border-bottom: solid .5rem $primary-color;

    .header-box {
        position: absolute;
        top: 40%;
        left: 5%;   
        color: white;
    }
    
    .header-cta {
        position: absolute;
        bottom: 10%;
        left: 70%;  
        @include breakpoint-down(medium){
            left: 50%;
            transform: translateX(-50%);
        } 
        color: white;
        &__content {
            position: relative;
        }
    }
}

.link-arrow-container{
    $link-arrow-width: 12rem;
    @include breakpoint-down(small){
        $link-arrow-width: 8rem;
    } 
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -$link-arrow-width - 1rem;
    svg {
        width: $link-arrow-width;
        path {
            fill: none;
            stroke: $secondary-bright;
            stroke-width: 1px;
            stroke-linecap: round;
        }
    }
}


// Main-Content
.section-vision-mission {
    margin: 8rem 0;
}

.leaf-svg-container-top, .leaf-svg-container-middle, .leaf-svg-container-lower{
    position: absolute;
    width: 6rem;
    @include breakpoint-down(small){
        width: 4rem;
    }
    fill:none;
    stroke-width:.8rem;
    stroke-linecap:round;
    stroke:$primary-color;
    @include breakpoint-down(xsmall){
        display: none;
    }
}
.leaf-svg-container-top{
    top: 20%;
    left: -2rem;
    opacity: .6;
}
.leaf-svg-container-middle{
    top: 40%;
    left: -1rem;
}
.leaf-svg-container-lower{
    opacity: .6;
    top: 60%;
    left: 0rem;
}

.leaf-svg-container-flower {
    position: absolute;
    top: 20%;
    right: -10rem;
    width: 20rem;
    @include breakpoint-down(medium){
        top: 30%;
        right: -6rem;
        width: 12rem;
    }
    @include breakpoint-down(xsmall){
        display: none;
    }
    fill:none;
    stroke-width:.8rem;
    stroke-linecap:round;
    stroke: $primary-color;
    .non-faded-leaf {
        opacity: .7;
    }
    .faded-leaf {
        opacity: .45;
    }
}


.mission-cta-container{
    margin-top: 4rem;
}


