.main_plantbased {
    margin-top: 2rem;
    background: linear-gradient(to right, $secondary-bright-2 0%, $secondary-bright-2 65%, $secondary-bright 65%, $secondary-bright 100%);

    // May have been better to rewrite markdown to have relative positioning, but time higher prioritized
    @include breakpoint-down(medium){
        .plant-based-content{
            left: 2%;
            .paragraph {
                margin-left: 1rem;
            }
        }
    }
    @include breakpoint-down(small){
        .section-plant-based--plant-page{
            height: 40rem;
            min-height: 0;
            .paragraph {
                font-size: $body-small-ft;
            }
        }
        .plant-based-content{
            top: 5%;
            left: 50%;
            width: 90vw;
        }
        .plant-based-background--plant-page{
            display: none;
        }
    }
    @include breakpoint-down(xsmall){
        .section-plant-based--plant-page{
            height: 60rem;
        }
    }
}


.section-products {
    margin: 0 $sidebar-gutter 5rem 0;
    // http://www.independent-software.com/set-stacking-order-of-pseudo-elements-below-parent-element.html
    position: relative;
    z-index: $zi-base;
}

.product-container {
    margin-top: 18rem;
    
    &--float-right {
        float: right;
    }
    &--floated {
        @include clearfix;
    }
    
    // May have been better to rewrite markdown to have relative positioning, but time higher prioritized
    @include breakpoint-down(small){
        margin-top: 10rem;
        margin-bottom: 10rem;
        &__headline{
            font-size: $headline-small-ft;
        }
        &__copy{
            max-width: 60vw;
            .paragraph{
                font-size: $body-small-ft;
            }
        }
    }
    @include breakpoint-down(xsmall){
        height: 15rem;
        margin-top: 10rem;
        margin-bottom: 55rem;
        &__content{
            top: 55%;
        }
        &__copy{
            max-width: 70vw;
        }
    }

}

@include breakpoint-down(small){
    .contact-container{
        margin-top: 4rem;
        width: 80%;
        .paragraph{
            font-size: $body-small-ft;
        }
    }
}