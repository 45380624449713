.header{
    min-height: 8rem;
}

.main-with-sidebar{
    margin-left: $sidebar-gutter;
    @include breakpoint-down(medium){
        margin-left: 1rem;
    }
}

