.form {
    
    textarea {
      resize:none;
    }

    input, textarea {
        width:100%;
        border:1px solid #CCC;
        background:#FFF;
        margin:0 0 5px;
        padding:10px;
        border-radius: 2px;
        // (will result in Mariam Libre, check with team)
        // font-family: inherit; 
        font-family: 'Rubik', sans-serif;
    }

    textarea {
        height: 13rem;
    }

    input:focus, textarea:focus {
        outline: none;
        border:1px solid #999;
    }

    .form--validated:invalid {
        border-left: 3px solid $secondary-accent-1;
    }

    .form--validated:valid {
        border-left: 3px solid $primary-color;
    }

    &:invalid input[type="submit"]{
        border: 1px solid $secondary-accent-1;
        color: $contrast-dark;
    }
    
    &:valid input[type="submit"]{
        transition: all 300ms ease-in-out;
        border: 1px solid $primary-color;
        color: $contrast-dark;
        &:hover, &:focus {
            background-color: $primary-color;
            color: $secondary-bright;
            cursor: pointer;
        }
    }

}

.form-feedback {
    visibility: hidden;
    height: 0;
    transform: visibility 100ms, height 200ms ease-in-out;
    text-align: center;
    border-radius: 4px;
    color: $secondary-bright;
    
    &.form-feedback--success{
        background-color: $primary-color;
    }
    &.form-feedback--fail{
        background-color: $secondary-accent-1;
        a {
            text-decoration: none;
            color: $contrast-dark;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    &.form-feedback--show{
        padding: .5rem 2rem;
        visibility: visible;
        height: inherit;
    }

}