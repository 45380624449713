* {
    margin: 0;
    padding: 0;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  html {
    font-size: 62.5%; // 1rem=10px, 10/16=62.5%

    @include breakpoint-down(medium){
      font-size: 50%; // 1rem=8px
    }
    @include breakpoint-down(small){
      font-size: 50%; // 1rem=8px
    }
    @include breakpoint-up(xlarge){
      font-size: 80%; 
    }
    @include breakpoint-up(xxlarge){
      font-size: 100%; 
    }
  }
  
  body {
    box-sizing: border-box;
  }

  ::selection{
    background-color: $secondary-accent-1;
    color: $secondary-bright;
  }