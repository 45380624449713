

.colour-box {
    border-left: solid $secondary-accent-1;
    padding-left: 1rem;
}



.product-container {
    max-width: 65%;
    height: 50rem;
    position: relative;
    background-color: $secondary-bright;
    $style-1-col: $secondary-rest-1;
    $style-2-col: $contrast-dark;
    $style-3-col: $secondary-accent-1;


    @include offsetborder;
    @include offsetbackground;

    img {
        // https://stackoverflow.com/questions/5804256/image-inside-div-has-extra-space-below-the-image
        display: block;
        // https://www.w3docs.com/snippets/css/how-to-auto-resize-an-image-to-fit-an-html-container.html
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    
    &--style-1 {
        &::after {
            border: solid 2px $style-1-col;
        }
        &::before {
            background-color: $style-1-col;
        }
    }
    
    &--style-2 {
        &::after {
            border: solid 2px $style-2-col;
        }
        &::before {
            background-color: $style-2-col;
        }
    }


    
    &__content {
        position: absolute;
        top: 5%;
        left: -51%;
    }
    
    &__headline {
        color: $secondary-bright;
        border-radius: 3px;
        border-radius: 3px;
        &--style-1{
            color: $secondary-bright;
            background: $style-1-col;
        }
        &--style-2{
            background: $style-2-col;
        }
        position: relative;
        display: inline-block;
        padding: 0 1rem;
        font-size: $headline-large-ft;
        text-transform: uppercase;
        z-index: 500;
    }

    &__copy {
        border-radius: 3px;
        color: $contrast-dark;
        position: relative;
        display: block;
        padding: 2rem;
        margin-top: 2rem;
        max-width: 50rem;

        font-size: $headline-large-ft;
        z-index: 500;
        background: rgba($secondary-bright, .85);
    }

    
}

.contact-container {
    position: relative;
    margin-top: 15rem;
    padding: 2rem;
    width: 65%;
    $style-3-col: $secondary-accent-1;
    background-color: $secondary-bright;
    @include offsetborder;
    @include offsetbackground;

    &--style-3 {
        &::after {
            border: solid 2px $style-3-col;
        }
        &::before {
            background-color: $style-3-col;
        }
    }

    &__button-container{
        text-align: center;
    }
}