$section-top-margin: 10rem;

.main-consultancy{
    .highlight-headline-box--left{
            margin-bottom: 3rem;
            margin-left: 3rem;
    }

    .paragraph{
        @include breakpoint-down(medium){
            font-size: $body-small-ft;
            margin-bottom: 0;
        }
    }
    
}

.section-cons-fields{
    padding-top: $section-top-margin;
    
    @include breakpoint-down(medium){
        background: linear-gradient(to left, rgba($secondary-bright-2, .5) 0%, rgba($secondary-bright-2, .5) 65%, transparent 65%, transparent 100%);
        padding-bottom: 4rem;
    }

    &__content{
        margin: 1rem 2rem;
        font-size: $body-ft;
        white-space: nowrap;
        @include breakpoint-up(xxlarge){
            font-size: $body-ft;
        }
        @include breakpoint-up(xxxlarge){
            font-size: $headline-small-ft;
        }
        @include breakpoint-down(medium){
            flex-wrap: wrap;
            margin: 1rem 4rem;
        }
    }

    &__list{
        flex: 4;
        line-height: 4rem;
        padding: 0 2rem;
        border-left: 2px solid $primary-color;
        ul{
            list-style-type: none;
        }
    }
    
    &__img-container{
        // flex: 1;
        @include breakpoint-down(medium){
            // order: -1;
            margin-top: 4rem;
        }
        position: relative;
        @include offsetborder;
        &::after {
            border: solid 2px $primary-color;
        }
        @include offsetbackground;
        &::before {
            background-color: $primary-color;
        }
        
        height: 28rem;
        @include breakpoint-up(xxlarge){
            height: 35rem;
        }
        @include breakpoint-up(xxxlarge){
            height: 40rem;
        }
        img{
            height: 100%;
        }
    }
}

.section-cons-process{
    // TEMP - should have more coherent background over sections
    background-color: $secondary-bright-2;
    z-index: $zi-behind;
    margin-top: $section-top-margin;
    padding: $section-top-margin 0;
    @include breakpoint-down(small){
        padding: 2rem 0;
    }
    .headline-right{
        margin-right: 20rem;
        margin-bottom: 8rem;
        @include breakpoint-down(medium){
            margin-right: 5rem;
        }
        @include breakpoint-down(small){
            margin-bottom: 0;
        }
    }

    &__process-steps{
        margin-top: 4rem;
        @include breakpoint-down(small){
            flex-direction: column;
            align-items: center;
        }
    }
    &__progress-card-parent{
        margin: 0 2rem;
        width: 20%;
        @include breakpoint-down(medium){
            width: 22%;
            margin: 0 .5rem;
            padding-bottom: 2rem;
        }
        @include breakpoint-down(small){
            width: 80%;
            margin: 2rem 0;
            padding-bottom: 4rem;
        }
        @include breakpoint-down(xsmall){
            width: 90%;
        }
        position: relative;
        background-color: $secondary-bright;
        transform: scale(1);
        transition: transform 200ms ease-in-out;
        &:hover{
            transform: scale(1.05);
        }
    }
    &__progress-card{
        padding: 0 2rem;
        .number-container{
            position: relative;
            // display: inline-block;
            text-align: center;
            margin: 0 auto;
            padding: 2rem 0;
            font-size: $headline-mid-ft;
            color: $secondary-dark;
            &::after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -52%);
                width: 5rem;
                height: 5rem;
                border: 3px solid $secondary-rest-1;
                border-radius: 50%;
            }
        }
        @include offsetborder;
        &::after {
            border: solid 2px $secondary-accent-1;
        }
    }
}

.section-cons-framework {
    margin-top: $section-top-margin;
    .highlight-headline-box{
        margin-bottom: 3rem;
    }
    &__summary-container{
        display: block;
    }
    &__summary{
        margin: 0 auto;
        position: relative;
        background-color: $secondary-bright;
        padding: 2rem;
        width: 50%;
        @include breakpoint-down(small){
            width: 80%;
        }
        @include breakpoint-down(xsmall){
            width: 90%;
        }
        @include offsetborder;
        &::after {
            border: solid 2px $secondary-accent-4;
        }
        @include offsetbackground;
        &::before {
            background-color: $secondary-accent-4;
        }
    }

    .framework-container {
        position: relative;
        @include breakpoint-down(small){
            margin-bottom: 18rem;
        }
    }
    .framework-bg{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        @include breakpoint-down(small){
            left: -53%;
            width: 150%;
        }
        @include breakpoint-down(xsmall){
            left: -210%;
            width: 300%;
        }
        svg {
            width: 100%;
            height: 100%;
            path {
                fill: none;
                stroke: $secondary-accent-1;
                stroke-width: .4px;
                stroke-linecap: round;
            }
        }
    }

    .dsp-grid-framework{
        margin-top: 5rem;
        grid-template-columns: repeat(5, 1fr [col-start]);
        grid-template-rows: repeat(5, 1fr);
        @include breakpoint-down(small){
            grid-template-columns: repeat(3, 1fr [col-start]);
        }
        
        [class^="item"]{    
            position: relative;
            // margin: 3rem auto;
            margin: 0 2rem;
            @include breakpoint-down(small){
                margin: .5rem .5rem;
            }

            svg {
                display: inline-block;
                height: 6rem;
                path {
                    stroke: $contrast-dark;
                    fill: $contrast-dark;
                }
                .path-highlight{
                    fill: $secondary-accent-1;
                    stroke: $secondary-accent-1;
                }
            }
        }

        .framework-item__text-container{
            padding-bottom: 1rem;
        }
        
        [class*="framework-item-container"]{
            padding-top: 1rem;
            transition: transform 800ms ease-in-out;
            transform: scale(1);
            &.active {
                transform: scale(1.05);
                .cross-hair{
                    transform-origin: center;
                    animation: animate-aim 800ms ease-in-out forwards;
                }
                #ruler-tick-1{
                    animation: animate-ruler-tick-1 1200ms ease-in-out forwards;
                }
                #ruler-tick-2{
                    animation: animate-ruler-tick-2 1200ms ease-in-out forwards;
                }
                #ruler-tick-3{
                    animation: animate-ruler-tick-3 1200ms ease-in-out forwards;
                }
                .magnifier-content{
                    animation: animate-analytics 1200ms ease-in-out forwards;
                }
                .rocket-fuel{
                    transform-origin: 36% 28%;
                    animation: animate-rocket 1200ms ease-in-out forwards;
                }
                .move-controller{
                    animation: animate-controller 1200ms ease-in-out forwards;
                }

            }
            background-color: $secondary-bright;
            text-align: center;
            border: solid 2px $secondary-accent-4;

        }
        .item-2 {
            grid-column: 2;
            grid-row: 2;
        }
        .item-3 {
            grid-column: 3;
            grid-row: 3;
        }
        .item-4 {
            grid-column: 4;
            grid-row: 4;
            @include breakpoint-down(small){
                grid-column: 2;
            }
        }
        .item-5 {
            grid-column: 5;
            grid-row: 5;
            @include breakpoint-down(small){
                grid-column: 1;
            }
        }
    }
}

.section-cons-more-info {
    position: relative;
    $arrow-width: 14rem;
    $arrow-start-left: 10rem;
    .arrow-container{
        display: inline-block;
        position: absolute;
        top: -16rem;
        left: $arrow-start-left;
        svg {
            width: $arrow-width;
            path {
                fill: none;
                stroke: $secondary-accent-2;
                stroke-width: 1px;
                stroke-linecap: round;
            }
        }
    }
    .highlight-headline-box{
        margin-left: $arrow-start-left + $arrow-width + 2rem;
    }
    margin-top: $section-top-margin;
    padding-top: 5rem;
    background-color: $secondary-bright-2;
    &__goto{
        text-align: baseline;
        margin-top: 2rem;
        text-align: center;
        padding: 2rem 0;
    }
}