.dsp-grid {
    display: grid;
}

.center {
    margin: 0 auto;
}

.flex {
    display: flex;
}

.flex-space-evenly {
    justify-content: space-evenly;
}

.flex-end {
    justify-content: flex-end;
}

.hide-on-medium-down {
    @include breakpoint-down(medium){
        display: none;
    }
}

.hide-on-medium-up {
    @include breakpoint-up(medium){
        display: none;
    }
}

.hide-on-large-up {
    @include breakpoint-up(large){
        display: none !important;
    }
}

.hide-on-small-down {
    @include breakpoint-down(small){
        display: none;
    }
}


.completely-hidden {
    display: none;
}

.bold {
    font-weight: 700;
}