.footer {
    position: relative;
    background-color: $secondary-bright;
    color: $contrast-dark;
    padding-bottom: 1rem;
    border-top: 1px solid $primary-color;

    &__bg {
        svg {
            height: 100%;
            circle {
                fill: $secondary-accent-1;
            }
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            @include breakpoint-down(xsmall){
                bottom: 0;
                top: 50%;
                height: 50%;
            }
        }
    }

    &__content{
        padding: 3rem 2rem 3rem 5rem;
        @include breakpoint-down(medium){
            padding: 3rem 0rem;
        }
    }
    
    &__company{
        flex: 1;
        @include breakpoint-down(small){
            flex: 2;
        }
    }
    &__info{
        flex: 2;
        @include breakpoint-down(xsmall){
            flex: 1;
        }
        border-left: 1px solid grey;
        padding-left: 2rem;
        @include breakpoint-down(medium){
            .nav__content--footer{
                font-size: $small-ft;
                margin-right: 1rem;
                display: flex;
                @include breakpoint-down(small){
                    float: none;
                    flex-direction: column;
                    align-content: flex-start;
                    .nav__first-lev--footer:hover {
                        background-image: none;
                        .nav__link{
                            color: $secondary-accent-1;
                        }
                    }
                }
                .nav__link{
                    padding: 3.5rem 3rem;
                    @include breakpoint-down(small){
                        padding: 1rem 3rem;
                    }
                }

            }
        }
    }
    &__copyright{
        text-align: center;
        margin: auto;
        font-size: $very-small-ft;
    }
    &__logo{
        height: 12rem;
        svg {
            .cls-1{fill:#fff;}
            .cls-2{fill:#323e48;}
            .cls-3{fill:#a2d45e;}
        }
    }
}