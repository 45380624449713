.section-consultancy{
    position: relative;
    z-index: $zi-top-ui-bg;
    @include breakpoint-down(medium){
        margin-top: -.6rem;
    }

} 

.consultancy-content {
    position: absolute;
    top: 30%;
    left: 10%;
    
    @include breakpoint-down(small){
        width: 80vw;
    }
    @include breakpoint-down(xsmall){
        width: 90vw;
    }
    @include breakpoint-up(large){
        top: 20%;
    }
    @include breakpoint-down(medium){
        top: 30%;
        left: 2%;
     }
    @include breakpoint-down(small){
        top: 5%;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba($secondary-bright, .95);
        border-radius: 3px;
        border: 1px solid $secondary-rest-1;
        padding: 1rem;
    }
    
    &__secondary-heading {
        left: 10%;
    }

    .highlight-headline-box{
        margin: 0;
        margin-bottom: 2rem;
    }

    &__paragraph {
        font-size: $body-small-ft;
        width: 40rem;
        margin-left: 4rem;
        @include breakpoint-down(small){
            margin-left: 0;
            width: auto;
        }
    }

    @include breakpoint-down(small){
        &__button {
            text-align: center;
        }
    }
}

.consultancy__go-to {
    position: absolute;
    top: 80%;
    left: 70%;
    @include breakpoint-down(medium){
        left: 67%;
    }
}

.consultancy-background{
    background: linear-gradient(to bottom right, transparent 0%, transparent 60%, $secondary-bright-2 60%, $secondary-bright-2 100%);
    svg {
        display: block;
        border-bottom: 5px solid $primary-color;
        width: 100%;
        max-height: 95vh;
        @include breakpoint-down(small){
            min-height: 55rem;
        }
        path {
            fill: none;
            stroke-width: 1.5px;
        }

        
        // Below animated
        .hidden{
            display: none;
        }
        .normalized-pathlengths {
            stroke-dasharray: 1;
            stroke-dashoffset: 1;
        }
        #branch-1-path-1{
            stroke: $secondary-rest-3;
        }
        #branch-1-path-2{
            stroke: $secondary-rest-2;
        }
        #branch-1-path-3{
            stroke: $secondary-rest-1;
        }
        #branch-1-path-5{
            stroke: $secondary-rest-1;
        }
        #branch-2-path-1{
            stroke: $secondary-rest-3;
        }

        // Below static
        #branch-1-path-4{
            stroke: $secondary-rest-2;
        }
        #branch-2-path-2{
            stroke: $secondary-rest-3;
        }
    }
}
