$primary-color: #a4be52;
$secondary-dark: #36393c;
$secondary-bright: #ffffff;
$secondary-bright-2: #efefeb;
$secondary-rest-1: #739570;
$secondary-rest-2: #819565;
$secondary-rest-3: #b4be96;
$secondary-accent-1: #d36a3a;
$secondary-accent-2: #f3ca4d;
$secondary-accent-3: #f5dd7e;
$secondary-accent-4: rgb(207, 173, 89);
$warning: #d62d13;
$contrast-dark: #4a5b78;

// Font-sizes, from https://type-scale.com/, using Major Third, base-size 10px
$headline-huge-ft: 7.5rem;
$headline-very-large-ft: 6rem;
$headline-large-ft: 4.8rem;
$headline-mid-ft: 3.8rem;
$headline-small-ft: 3rem;
$small-headline-ft: 2.4rem;
$body-ft: 2rem;
$body-small-ft: 1.8rem;
$small-ft: 1.6rem;
$very-small-ft: 1.25rem;
$tiny-ft: 1rem;
$tiniest-ft: 0.8rem;

// Button sizes for responsive
$button-small-text: $body-ft;
$button-very-small-text: $small-ft;

// Sidebar
$sidebar-gutter: 4rem;


// Z-indices
$zi-absolute-top: 999; // modal, hamburger-icon
$zi-absolute-top-bg: 998; // modal, hamburger-icon

$zi-overlay: 750; // overlay while modal

$zi-top-ui: 500; // Nav and footer
$zi-top-ui-bg: 499; // Nav and footer

$zi-mid: 200; // sidebar
$zi-base: 1;
$zi-behind: -1;


// CSS variables
:root {
    --border-size-vision-mission: 20px;
    @include breakpoint-down(medium){
        --border-size-vision-mission: 16px;
    }
    @include breakpoint-down(xsmall){
        --border-size-vision-mission: 12px;
    }
}
