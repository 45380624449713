button, .button {
    text-decoration: none;
    position: relative;
    display: inline-block;
    padding: 1rem 2rem;
    @include breakpoint-down(small){
        padding: .5rem .5rem;
    } 
    border: 1px solid $secondary-accent-1;
    border-radius: 3px;
    cursor: pointer;
    color: white;
    // color: $contrast-dark;
    font-size: $body-ft;  
    overflow: hidden;
    
    background-color: $secondary-accent-1;
    box-shadow: 2rem -2rem $secondary-accent-2;
    
    @include breakpoint-down(small){
        box-shadow: .5rem -.5rem $secondary-accent-2;
    }  
    
    transition: box-shadow 200ms ease-in;
    &:hover {
        box-shadow: .5rem -.5rem $secondary-accent-2;
    }
    
    &--cta {
        text-align: center;
        font-size: $body-ft;  
        box-shadow: 1rem -1rem $secondary-accent-2;
        @include breakpoint-down(small){
            box-shadow: .5rem -.5rem $secondary-accent-2;
        }  
        &:hover{
            box-shadow: .2rem -.2rem $secondary-accent-2;
        }
    }   
    
    &--large-text{
        font-size: $headline-mid-ft;  
        @include breakpoint-down(small){
            font-size: $button-small-text;
        }    
        @include breakpoint-down(xsmall){
            font-size: $button-very-small-text;
        }    
    }

  
  
  }