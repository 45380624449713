@mixin clearfix {
    &::after {
        content: "";
        clear: both;
        display: table;
      }
}

@mixin navunderline {
  background-image: linear-gradient(120deg, $secondary-accent-1 0%, $secondary-accent-1 100%);
  background-repeat: no-repeat;
  background-size: 50% 0.3rem;
  background-position: 50% 65%;
}

@mixin offsetborder {
  &::after {
    content: " ";
    position: absolute;
    top: -.8rem;
    left: -.8rem;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
}

@mixin offsetbackground {
  &::before {
    content: " ";
    position: absolute;
    top: .8rem;
    left: .8rem;
    width: 100%;
    height: 100%;
    z-index: $zi-behind;
  }
}