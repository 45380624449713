@import url('https://fonts.googleapis.com/css2?family=Miriam+Libre:wght@400;700&family=Rubik:wght@300;400;500&display=swap');

body {
    font-family: 'Miriam Libre', sans-serif;
    // font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 1.7;
    color: $contrast-dark;
}

.primary-heading {
  font-size: $headline-very-large-ft;
  
  @include breakpoint-down(small){
    font-size: $headline-mid-ft;
  }

}

.secondary-heading {
  font-size: $headline-small-ft;
  margin-bottom: 1rem;

  @include breakpoint-down(small){
    font-size: $body-ft;
  }
}

.tertiary-heading {
  font-size: $body-ft;
  margin-bottom: 1rem;
}

.paragraph {
  font-family: 'Rubik', sans-serif;
  font-size: $body-ft;
  margin-bottom: 2rem;
  @include breakpoint-down(small){
    font-size: $small-ft;
  }
}

.highlight-1 {
  display: inline-block;
  background-color: $secondary-accent-1;
  border-radius: 4px;
  padding: .5rem 1rem;
}

.highlight-headline-box {
  display: inline-block;
  color: $secondary-bright;
  font-size: $headline-large-ft;
  background-color: $secondary-accent-1;
  border-radius: 6px;
  padding: .5rem 1rem;
  
  @include breakpoint-down(small){
    font-size: $small-headline-ft;
  }

  &--smaller{
    font-size: $headline-mid-ft;
    padding: 1rem;
  }
}

.highlight-2 {
  color: $primary-color;
}
