.section-presence-expertise {
    margin-top: 8rem;
    @include breakpoint-down(medium){
        margin-top: 4rem;
    }

}

.globe-container{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 15rem;
    
    @include breakpoint-down(small){
        width: 10rem;
    }
    @include breakpoint-down(xsmall){
        width: 8rem;
    }

    .globe-non-highlight{
        fill: rgba($contrast-dark, .8);
    }
    .globe-highlight {
        fill: $secondary-accent-1;
    }
}

.leaf-container{
    position: relative;
    .freestanding-leaf{
        width: 6rem;
        stroke-width:.6rem;
        stroke-linecap:round;
        fill:none;
        stroke:$primary-color;

        @include breakpoint-down(small){
            width: 4rem;
        }
        @include breakpoint-down(xsmall){
            width: 4rem;
        }
    }
    .leaf-no-1, .leaf-no-2, .leaf-no-3, .leaf-no-4, .leaf-no-5{
        position: absolute;
        z-index: 1;
    }
    .leaf-no-1{
        top: -2rem;
        left: -7rem;
        transform: rotate(90deg);
        @include breakpoint-down(medium){
            left: -4rem;
        }
    }
    .leaf-no-2{
        top: 18rem;
        left: -5rem;
        @include breakpoint-down(medium){
            left: -3rem;
        }
    }
    .leaf-no-3{
        top: -8rem;
        right: -5rem;
    }
    .leaf-no-4{
        top: 3rem;
        right: -8rem;
        @include breakpoint-down(xsmall){
            right: -4rem;
        }
    }
    .leaf-no-5{
        top: 15rem;
        right: -4rem;
        transform: rotate(90deg);        
    }
}

// Redirect section
.point-to-services{
    position: relative;
    margin: 4rem;
    background-color: $secondary-bright-2;
}

$arrow-width-large-screens: 60%;
$arrow-width-small-screens: 80%;
.link-arrow-to-services{
    width: $arrow-width-large-screens;
    fill:none;
    stroke-width:.05rem;
    stroke-linecap:round;
    stroke: rgba($contrast-dark, .8);
    @include breakpoint-down(small){
        width: $arrow-width-small-screens;
    }
}

.how-we-do-it-button{
    position: absolute;
    top: 16%;
    left: $arrow-width-large-screens - 4%;
    @include breakpoint-down(small){
        left: $arrow-width-small-screens - 1%;
    }
    @include breakpoint-down(xsmall){
        top: 0;
        left: $arrow-width-small-screens - 1%;
    }
}

.direct-teams-section{
    position: absolute;
    top: 75%;
    left: 25%;
    @include breakpoint-down(small){
        font-size: $button-small-text;
        left: $arrow-width-small-screens - 50%;
    }
    @include breakpoint-down(xsmall){
        top: 75%;
        font-size: $button-very-small-text;
    } 
}

// Team section
.section-team {
    margin: 3rem $sidebar-gutter+1rem;
    
    @include breakpoint-down(medium){
        margin: 3rem 1rem;
    }

    &__background{
        background: linear-gradient(to right, $secondary-bright-2 0%, $secondary-bright-2 70%, transparent 70%, transparent 100%);
        position: relative;
        border-radius: 4px;
    }

    &__headline-box{
        margin: 2rem 0 0 2rem;
    }
}

.dsp-grid-team{
    grid-template-columns: repeat(2, 1fr [col-start]);
    grid-template-rows: repeat(2, 1fr);
    margin: 5rem 1rem 0 0;
    
    @include breakpoint-down(small){
        grid-template-columns: repeat(1 , 1fr);
        margin: 2rem 0 0 0;
    }
}





