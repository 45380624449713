.modal {
    display: block;
    border-radius: 3px;

    width: 70%;
    height: 85%;
    
    @include breakpoint-down(medium){
        width: 95%;
        height: 95%;
    }

    position: fixed;

    z-index: $zi-absolute-top;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);    
    background: white;
    box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);

    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $zi-overlay;
        background: rgba(0, 0, 0, 0.6);
        display: none;
    }

    &__content {
        $modal-top-margin: 4rem;
        position: absolute;
        top: 0;
        left: 0;
        margin: $modal-top-margin 0 0 5rem;
        padding-right: 5rem;
        height: 90%;
        align-items: stretch;
        @include breakpoint-down(small){
            margin: $modal-top-margin 0 0 1rem;
            padding-right: 1rem;
            .secondary-heading{
                font-size: $small-headline-ft;
            }
            .paragraph, .tertiary-heading {
                font-size: $body-small-ft;
            }
        }
        @include breakpoint-down(small){
            flex-direction: column;
        }
        overflow: auto;
        &--aside {
            padding-right: 3rem;
            border-right: 1px solid #ccc;
            @include breakpoint-down(small){
                border-right: none;
            }
            flex: 1;   
            
            .modal-email {
                font-size: $small-ft;
                &__link{
                    text-decoration: none;
                    color: $contrast-dark;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    
        &--text{
            flex: 2;
            @include breakpoint-down(medium){
                flex: 4;
            }
            padding-left: 2rem;
            margin: 1rem auto;
            @include breakpoint-down(small){
                padding-left: 0rem;
                border-top: 1px solid #ccc;
            }
        }
    }


    &__image-container  {
        img {
            width: 100%;
            height: auto;
            border-radius: 3px;
            @include breakpoint-down(small){
                width: 40%;
                height: auto;
            }
            @include breakpoint-down(xsmall){
                width: 60%;
                height: auto;
            }
        }
        &--1{
            img {
                background-color: $secondary-accent-1;
            }
        }
        &--2{
            img {
                background-color: $primary-color;
            }
        }
        &--3{
            img {
                background-color: $secondary-dark;
            }
        }
        &--4{
            img {
                background-color: $secondary-accent-2;
            }
        }
    }

    &__close-button {
        float: right;
        width: 5rem;
        opacity: .5;
        &:hover {
            cursor: pointer;
        }
    }

    &__close-button-container{
        @include clearfix;
    }

    &__linked-in-logo{
        svg {
            position: relative;
            display: block;
            width: 5rem;
            fill: #ccc;
            // &:hover{
            //     fill: $secondary-accent-1;
            // }
        }
        &--1{
            svg {
                &:hover{
                    fill: $secondary-accent-1;
                }
            }
        }
        &--2{
            svg {
                &:hover{
                    fill: $primary-color;
                }
            }
        }
        &--3{
            svg {
                &:hover{
                    fill: $secondary-dark;
                }
            }
        }
        &--4{
            svg {
                &:hover{
                    fill: $secondary-accent-2;
                }
            }
        }
    }


    
    display: none;
    &--open {
        display: block;
        // .modal-overlay {
        //     display: block;
        // }
    }
}

// Ensure background not scrollable while modal open
body.modal--open {
    overflow: hidden;
}
 
  
  

  

